import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import DropdownInput from '../../../components/DropdownInput';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import PerformanceReportImage from '../../../assets/images/home-upgrades/performance-report.jpg';
import ElectricianImage from '../../../assets/images/home-upgrades/electrician.jpg';
import HouseTourImage from '../../../assets/images/home-upgrades/house-tour.jpg';
import ApplianceInstallImage from '../../../assets/images/home-upgrades/appliance-install.jpg';
import CoupleImage from '../../../assets/images/home-upgrades/couple-on-phone.jpg';
import HomeImage from '../../../assets/images/home-upgrades/home.jpg';
import Link from '../../../components/Link';

const Tab = ({ name, idx, isSelected, onClick }) => (
  <Button
    id={`tab-${idx}`}
    type="button"
    role="tab"
    aria-controls={`tabpanel-${idx}`}
    aria-selected={isSelected ? 'true' : 'false'}
    onClick={onClick}
    sx={{
      p: 1.25,
      borderRadius: '6px',
      backgroundColor: isSelected ? 'primary.main' : 'transparent',
      color: isSelected ? 'common.white' : 'common.black',
      border: 'none',
      fontWeight: 600,
      lineHeight: '20px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: isSelected ? 'primary.main' : 'transparent',
      },
    }}
  >
    {name}
  </Button>
);

const TabContent = ({ title, img, imgAlt, children }) => (
  <Grid container spacing={7}>
    <Grid
      xs={12}
      md={7}
      item
      sx={{ fontSize: '1.125rem' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <HeadingWithFlair sx={{ fontSize: { xs: '2rem', md: '3rem' } }}>{title}</HeadingWithFlair>
      <Box>{children}</Box>
    </Grid>
    <Grid xs={0} md={5} item display={{ xs: 'none', md: 'flex' }}>
      <Box
        height="100%"
        sx={{
          borderRadius: '30px',
          border: '10px solid',
          borderColor: 'primary.main',
          height: { md: 400, xl: 577 },
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          component="img"
          height="100%"
          width="100%"
          src={img}
          alt={imgAlt}
          sx={{ objectFit: 'cover' }}
        />
      </Box>
    </Grid>
  </Grid>
);

const TABS = [
  {
    name: '1. Assess Your Home',
    content: (
      <TabContent
        title="1. Assess Your Home"
        img={PerformanceReportImage}
        imgAlt="A performance report on a phone"
      >
        <Box>
          <Typography mb={2}>
            Start with a detailed analysis of your home's energy consumption and get personalized
            recommendations from an energy professional. Receive a virtual energy audit from our{' '}
            <Link external href="https://goelectric.svcleanenergy.org/" sx={{ color: 'green.700' }}>
              Go Electric Advisor
            </Link>{' '}
            service.
          </Typography>
          <Typography mb={2}>
            Next, take inventory of your gas appliances, equipment, and vehicles to identify which
            are nearing the end of their life and the biggest energy consumers.
          </Typography>
          <Typography>
            For renters, note which appliances you can replace yourself, which may require owner
            approval, and consider implementing efficiency measures like insulation or
            weatherstripping.
          </Typography>
        </Box>
      </TabContent>
    ),
  },
  {
    name: '2. Home Health Checkup',
    content: (
      <TabContent
        title="2. Home Health Checkup"
        img={ElectricianImage}
        imgAlt="An electrician working"
      >
        <Box>
          <Typography mb={2}>
            Before upgrading to new electric appliances, ensure your home is energy-efficient and
            healthy. Have a contractor seal air leaks and address any mold or pest issues.
          </Typography>
          <Typography>
            If your home was built before 1979, it may require significant upgrades to the electric
            panel and wiring to meet increased electric demands.
          </Typography>
        </Box>
      </TabContent>
    ),
  },
  {
    name: '3. Explore Incentives',
    content: (
      <TabContent
        title="3. Plan your home upgrade and explore incentives"
        img={HouseTourImage}
        imgAlt="People touring a house"
      >
        <Box>
          <Typography mb={2}>
            Identify which home appliances are ready for an upgrade to electric. Major equipment
            like clothes dryers, water heaters, space heating and cooling systems, and kitchen
            appliances all have{' '}
            <Link
              external
              href="https://svcleanenergy.org/electric-home/"
              sx={{ color: 'green.700' }}
            >
              efficient electric equivalents
            </Link>
            .
          </Typography>
          <Typography mb={2}>
            Consider pairing these upgrades with technologies such as EV charging or solar + battery
            storage to maximize your savings and improve energy efficiency. Our{' '}
            <Link external href="https://goelectric.svcleanenergy.org/" sx={{ color: 'green.700' }}>
              Go Electric Advisor
            </Link>{' '}
            can help develop a detailed plan, prioritize upgrades, and offer tailored
            recommendations.
          </Typography>
          <Typography>
            To make your transition smoother, explore our range of incentives, including rebates and
            full-service support, to lower your upfront costs (see table above). Regional, state,
            and federal{' '}
            <Link
              external
              href="https://svcleanenergy.org/federal-rebates"
              sx={{ color: 'green.700' }}
            >
              programs
            </Link>{' '}
            offer significant savings through rebates, tax credits, and financing options.
          </Typography>
        </Box>
      </TabContent>
    ),
  },
  {
    name: '4. Install Your Upgrades',
    content: (
      <TabContent
        title="4. Install your new electric appliance"
        img={ApplianceInstallImage}
        imgAlt="A person installing an appliance"
      >
        <Box>
          <Typography mb={2}>
            Select the level of support you need for your home upgrade (see table above). SVCE
            provides options to connect you with local contractors who can install your electric
            appliance.
          </Typography>
          <Typography>
            Ensure that you or your contractor apply for the necessary building permits with your
            city or county to qualify for rebates.
          </Typography>
        </Box>
      </TabContent>
    ),
  },
  {
    name: '5. Compare Rates',
    content: (
      <TabContent
        title="5. Evaluate your electricity rate plan"
        img={CoupleImage}
        imgAlt="A couple looking at a phone"
      >
        <Box>
          <Typography mb={2}>
            Customers can benefit from greater energy cost savings by pairing home upgrades with an
            EV or Electric Home (E-ELEC) rate. This rate provides significant discounts on off-peak
            energy usage. Learn more about these savings and rates{' '}
            <Link
              external
              href="https://svcleanenergy.org/residential-rates/"
              sx={{ color: 'green.700' }}
            >
              here
            </Link>
            .
          </Typography>
        </Box>
      </TabContent>
    ),
  },
  {
    name: '6. Shut Off the Gas',
    content: (
      <TabContent title="6. Shut off the gas!" img={HomeImage} imgAlt="Image of a house">
        <Box>
          <Typography mb={2}>
            Once your home is fully electric, you can turn off your{' '}
            <Link
              external
              href="https://www.pge.com/en/account/service-requests/start-stop-transfer-service.html"
              sx={{ color: 'green.700' }}
            >
              PG&E gas service
            </Link>{' '}
            permanently.
          </Typography>
          <Typography>
            Say goodbye to high gas bills, and sleep more soundly knowing your home is forever safe
            from gas leaks and pollutants.
          </Typography>
        </Box>
      </TabContent>
    ),
  },
];

export default function Steps() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box py={{ xs: 4, md: 7 }}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          fontWeight={600}
          fontSize={{ xs: '2rem', md: '3rem' }}
          lineHeight="40px"
          textAlign="center"
          mb={4}
        >
          Steps to a Home Upgrade
        </Typography>

        <Box
          component="nav"
          role="tablist"
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            mb: 6,
            gap: 1,
          }}
        >
          {TABS.map(({ name }, idx) => (
            <Tab
              key={idx}
              idx={idx}
              name={name}
              isSelected={selectedTab === idx}
              onClick={() => setSelectedTab(idx)}
            />
          ))}
        </Box>

        <Box display={{ xs: 'block', md: 'none' }}>
          <DropdownInput
            value={selectedTab}
            options={TABS.map(({ name }, idx) => ({ value: idx, children: name }))}
            onChange={(e) => setSelectedTab(e.target.value)}
            sx={{
              mb: 4,
              border: '1px solid',
              borderColor: 'gray.600',
              borderRadius: '8px',
              overflow: 'hidden',
              '& .MuiInputBase-root': {
                p: 1,
                borderRadius: '4px',
              },
            }}
          />
        </Box>

        {TABS.map(({ content }, idx) => (
          <Box
            key={idx}
            component="section"
            id={`tabpanel-${idx}`}
            role="tabpanel"
            aria-labelledby={`tab-${idx}`}
            sx={{ display: selectedTab !== idx ? 'none' : 'block' }}
          >
            {content}
          </Box>
        ))}
      </Container>
    </Box>
  );
}
